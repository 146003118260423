import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './Home.sass';

function Home() {
    return (
        <div className='home'>
            <Container className='home__content'>
                <Row className='home__section'>
                    <Col lg={12}>
                        <h1>VAMANOS</h1>
                        <h2>Valencia Association of Merchants, Artists, Neighbors, and Organizations</h2>
                        <h3>Empowering communities for positive change</h3>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={9}>
                        <h2 className='home__uppercase'>What is VAMANOS?</h2>
                        <p className='home__center'>VAMANOS is a collective of merchants, artists, neighbors, and organizations advocating for positive changes in the Valencia Street Corridor of the Mission District, SF, CA.</p>
                        <div className='home__button-container'>
                            <a
                                aria-label='Join Vamanos in a new tab'
                                href='https://forms.gle/9FyeXvMNBN2xt5MH9'
                                target='_blank'
                            >
                                <Button variant='success'>Join VAMANOS</Button>
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <div className='home__grid'>
                            <Image className='home__grid-image home__grid-image--full' src='https://vamanossf.s3.us-west-1.amazonaws.com/blondies.jpg' alt="Blondies" />
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={9}>
                        <h2 className='home__uppercase'>Why VAMANOS?</h2>
                        <p className='home__center'>We understand that achieving positive change is a journey that requires the voices and insights of all community members. Everyone's input is vital in shaping the wellness of our corridor and driving meaningful results for all.</p>
                        <p className='home__center'>That's why VAMANOS is dedicated to creating a platform where we can openly and transparently discuss essential topics. By working collaboratively, we can make decisions that truly reflect the needs and aspirations of our community.</p>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <div className='home__grid'>
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/cyclops.jpg' alt="Cyclops" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/drummers.jpg' alt="Drummers" />
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={9}>
                        <h2 className='home__uppercase'>How VAMANOS?</h2>
                        <h3 className='home__text-thin'>Topic →  Outreach → Feedback → Consult → Result</h3>
                        <p className='home__center'>Our approach prioritizes community engagement, outreach, and sharing information concerning plans, challenges, and projects on the VALENCIA CORRIDOR, emphasizing inclusivity and cultural preservation.</p>
                        <h3>Our goal is to support long-standing and underrepresented businesses.</h3>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <div className='home__grid'>
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/mural-alley.jpg' alt="Mural Alley" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/colorful-storefront.jpg' alt="Colorful Storefront" />
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={9}>
                        <h2 className='home__uppercase'>Our Agenda</h2>
                        <ul>
                            <li>Public Safety</li>
                            <li>Promote Economic Revitalization</li>
                            <li>To support long-standing and underrepresented businesses</li>
                            <li>Beautification & Sanitization</li>
                            <li>Counter Gentrification</li>
                            <li>Fostering community engagement through arts and culture activities</li>
                        </ul>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={12}>
                        <div className='home__grid'>
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/puerto-alegre.jpg' alt="Puerto Alegre" />
                            <Image className='home__grid-image' src='https://vamanossf.s3.us-west-1.amazonaws.com/street-dancing.jpg' alt="Dancing on a Closed Street" />
                        </div>
                    </Col>
                </Row>
                <Row className='home__section'>
                    <Col lg={9}>
                        <h2 className='home__uppercase'>FAQ</h2>
                        <ul>
                            <li>
                                <p className='home__text-question'>Does it cost to be a Member?</p>
                                <p>Joining is free, although donations are appreciated.</p>
                            </li>
                            <li>
                                <p className='home__text-question'>Who can Join?</p>
                                <p>Local Businesses, Merchants, Artists, Neighbors, and Organizations</p>
                            </li>
                            <li>
                                <p className='home__text-question'>What is the commitment?</p>
                                <p>There is no commitment, but we encourage our members to engage with our communications by reading our emails, providing feedback, completing surveys, attending our bi-monthly meetings, and collaborating to identify effective ways to address challenges and share information with all members.</p>
                            </li>
                        </ul>
                    </Col>
                </Row>
                {/* Current Topics of Discussion //////////////////////////////////////// */}
                {/*<Row className='home__section'>*/}
                {/*    <Col lg={9}>*/}
                {/*        <h2 className='home__uppercase'>**Current Topics of Discussion**</h2>*/}
                {/*        <h3 className='home__align-left'>Valencia Bike Lane</h3>*/}
                {/*        <p>A center-running bike lane on Valencia was proposed in 2022 and constructed in 2023 without adequate outreach or consultation with local businesses, neighbors, and organizations, resulting in closures and financial losses for those businesses.</p>*/}
                {/*        <h4 className='home__align-left'>List of businesses that have been closed or forced to sell since 2022 because of the centered bike lane:</h4>*/}
                {/*        <ul className='home__ul-columns'>*/}
                {/*            <li>West of Pecos Laku</li>*/}
                {/*            <li>Audrey</li>*/}
                {/*            <li>Scarlet Sage</li>*/}
                {/*            <li>Los Amigos Restaurant</li>*/}
                {/*            <li>Chicken Time</li>*/}
                {/*            <li>We be Sushi</li>*/}
                {/*            <li>Valencia Cyclery</li>*/}
                {/*            <li>Five and Diamond</li>*/}
                {/*            <li>Biriani House</li>*/}
                {/*            <li>Ancora</li>*/}
                {/*            <li>Stonemill Matcha</li>*/}
                {/*            <li>Hawker Fare</li>*/}
                {/*            <li>Pi Bar</li>*/}
                {/*            <li>Phoenix Bar</li>*/}
                {/*        </ul>*/}
                {/*        <h4 className='home__align-left'>Action</h4>*/}
                {/*        <p>Pause construction of the latest SFMTA proposal until proper outreach and consultation occur with all businesses, residents, artists, and organizations along the Valencia Merchant Corridor.</p>*/}
                {/*        <p><a href='https://www.sfmta.com/media/40672/download?inline' target='_blank'>Proposed Valencia Bikeway Design (as of 9.27.24)</a></p>*/}
                {/*        <h4 className='home__align-left'>What is failing with the proposed Valencia Bikelane design</h4>*/}
                {/*        <ul>*/}
                {/*            <li>Safety for customers of the Parklets</li>*/}
                {/*            <li>Excess of Loading Zones reducing parking spaces for visitors, customers and residents</li>*/}
                {/*            <li>Decreasing accessibility to the area, forcing new and longstanding businesses</li>*/}
                {/*            <li>Drecresement of employment job opportunities</li>*/}
                {/*        </ul>*/}
                {/*        <h4 className='home__align-left'>What are we proposing?</h4>*/}
                {/*        <ul>*/}
                {/*            <li>To improve and relocate loading zones on Valencia Street to the nearby side streets</li>*/}
                {/*            <li>Moving Lyft bike racks to adjacent side streets</li>*/}
                {/*            <li>Establish the Mission Biking and Rolling Network to equip more streets with bike lanes, signs, and lights that ensure interconnectivity throughout the Mission District</li>*/}
                {/*            <li>Compensation for merchants affected by the middle bike lane and incentives to support new local businesses and artists, similar to the efforts on Taraval Street</li>*/}
                {/*        </ul>*/}
                {/*        <h4 className='home__align-left'>What are the positive things or goals about this plan?</h4>*/}
                {/*        <ul>*/}
                {/*            <li>A more extensive and safer bicycle network that improves accessibility for all cyclists in the Mission District</li>*/}
                {/*            <li>Ensuring that most parking spaces in the Valencia Street Corridor are reserved for customers and visitors, enhancing tourism, commerce, and vitality</li>*/}
                {/*            <li>Support merchants who closed businesses with new opportunities to reopen or encourage new ventures</li>*/}
                {/*        </ul>*/}
                {/*        <div className='home__button-container'>*/}
                {/*            <a*/}
                {/*                aria-label='Join Vamanos in a new tab'*/}
                {/*                href='https://www.change.org/p/sfmta-director-jeff-tumlin-must-resign'*/}
                {/*                target='_blank'*/}
                {/*            >*/}
                {/*                <Button variant='success'>Sign the Petition</Button>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </Container>
        </div>
    );
}

export default Home;
